import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const CommonLoginDataSlice=createSlice({
    name:'commonlogin',
    initialState,
    reducers:{
        setCommonLoginDataSlice(state, action){
            state.data = action.payload.data
        }
        
    }

})

export const{setCommonLoginDataSlice}=CommonLoginDataSlice.actions;
export default CommonLoginDataSlice.reducer

