import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = { data: [], status: STATUSES.IDLE };

const ProfileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    
    setProfileSlice(state, action) {
      state.data = action.payload.data;
    },
    setSingleProfileSlice(state, action) {
      state.singleData = action.payload.data;
    }
  },
});

export const { setProfileSlice,setSingleProfileSlice}=  ProfileSlice.actions;
export default ProfileSlice.reducer;
