/// Menu
//import MetisMenu from "metismenujs";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Button, Modal } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from "react-bootstrap/Collapse";
/// Link
import { Link } from "react-router-dom";

import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getSuperLoginDataThunk } from "../../../Thunk/LoginThunk/SuperLoginThunk";
import { getEmpLoginDataThunk } from "../../../Thunk/LoginThunk/EmpLoginThunk";
import { notifyError } from "../../components/PluginsMenu/Toastr/Toastr";
// import { ThemeContext } from "../../../context/ThemeContext";

const reducer = (previousState: any, updatedState: any) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const d = new Date();
  // const {
  // 	iconHover,
  // 	sidebarposition,
  // 	headerposition,
  // 	sidebarLayout,
  // } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  // const [addEvent, setAddEvent] = useState(false);
  const [addHeart, setAddHeart] = useState(false);

  const dispatch = useAppDispatch();
  const loginData = useAppSelector((state: any) => state.commonlogin.data);

  useEffect(() => {
    let userType = localStorage.getItem("userType");
    let userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}"); // Providing default value
    if (userType === '"master"') {
      dispatch(getSuperLoginDataThunk(userDetails.userid));
    } else {
      dispatch(getEmpLoginDataThunk(userDetails.userid));
    }
  }, []);

  let empMenuList: any = [];
  if (Object.keys(loginData).length !== 0 && loginData.type !== "master") {
    loginData.tab
      .filter((tab: any) => tab.is_active === "1")
      .forEach((tab: any) => {
        if (tab.is_subtab === "0") {
          empMenuList.push({
            title: tab.tab_name,
            classsChange: "",
            iconStyle: <i className={`${tab.icon}`} />,
            to: getUrlFromActivity(tab.tab_id),
            content: [],
          });
        } else {
          const content = loginData.activity
            .filter((activity: any) => activity.tab_id === tab.tab_id)
            .map((activity: any) => ({
              title: activity.activity_title,
              to: activity.url,
              content: [],
              iconStyle: <i className={`${activity.icon}`} />,
            }));

          empMenuList.push({
            title: tab.tab_name,
            classsChange: "mm-collapse",
            iconStyle: <i className={`${tab.icon}`} />,
            to: "",
            content: content,
          });
        }
      });
  }

  function getUrlFromActivity(tab_id: string) {
    const activity = loginData.activity.find(
      (activity: any) => activity.tab_id === tab_id
    );
    return activity ? activity.url : null;
  }

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status: any) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status: any) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  /// Path
  let path1 = window.location.pathname;
  var path: any;
  path = path1.split("/");
  path = path[path.length - 1];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {loginData.type === "master"
            ? MenuList.map((data, index) => {
                let menuClass = data.classsChange;
                if (menuClass === "menu-title") {
                  return (
                    <li className={menuClass} key={index}>
                      {data.title}
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={` ${
                        state.active === data.title ? "mm-active" : ""
                      }`}
                      key={index}
                    >
                      {data.content && data.content.length > 0 ? (
                        <>
                          <Link
                            to={"#"}
                            className="has-arrow"
                            onClick={() => {
                              handleMenuActive(data.title);
                            }}
                          >
                            {data.iconStyle}
                            <span className="nav-text">{data.title}</span>
                          </Link>
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`${
                                menuClass === "mm-collapse" ? "mm-show" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map((subData, subIndex) => {
                                  return (
                                    <li
                                      key={subIndex}
                                      className={`${
                                        state.activeSubmenu === subData.title
                                          ? "mm-active"
                                          : ""
                                      }`}
                                    >
                                      {subData.content &&
                                      subData.content.length > 0 ? (
                                        <>
                                          <Link
                                            to={subData.to}
                                            className={
                                              subData ? "has-arrow" : ""
                                            }
                                            onClick={() => {
                                              handleSubmenuActive(
                                                subData.title
                                              );
                                            }}
                                          >
                                            {subData.iconStyle}
                                            <span className="nav-text">
                                              {subData.title}
                                            </span>
                                          </Link>
                                          <Collapse
                                            in={
                                              state.activeSubmenu ===
                                              subData.title
                                                ? true
                                                : false
                                            }
                                          >
                                            <ul
                                              className={`${
                                                menuClass === "mm-collapse"
                                                  ? "mm-show "
                                                  : ""
                                              }`}
                                            >
                                              {subData.content &&
                                                subData.content.map(
                                                  (subSubData, subSubIndex) => {
                                                    return (
                                                      <>
                                                        <li key={subSubIndex}>
                                                          <Link
                                                            className={`${
                                                              path ===
                                                              subSubData.to
                                                                ? "mm-active"
                                                                : ""
                                                            }`}
                                                            to={subSubData.to}
                                                          >
                                                            {subSubData.title}
                                                          </Link>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )}
                                            </ul>
                                          </Collapse>
                                        </>
                                      ) : (
                                        <Link to={subData.to}>
                                          {subData.iconStyle}
                                          {subData.title}
                                        </Link>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          </Collapse>
                        </>
                      ) : (
                        <Link to={data.to}>
                          {data.iconStyle}
                          <span className="nav-text">{data.title}</span>
                        </Link>
                      )}
                    </li>
                  );
                }
              })
            : empMenuList.map((data: any, index: any) => {
                let menuClass = data.classsChange;
                if (menuClass === "menu-title") {
                  return (
                    <li className={menuClass} key={index}>
                      {data.title}
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={` ${
                        state.active === data.title ? "mm-active" : ""
                      }`}
                      key={index}
                    >
                      {data.content && data.content.length > 0 ? (
                        <>
                          <Link
                            to={"#"}
                            className="has-arrow"
                            onClick={() => {
                              handleMenuActive(data.title);
                            }}
                          >
                            {data.iconStyle}
                            <span className="nav-text">{data.title}</span>
                          </Link>
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`${
                                menuClass === "mm-collapse" ? "mm-show" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map(
                                  (subData: any, subIndex: any) => {
                                    return (
                                      <li
                                        key={subIndex}
                                        className={`${
                                          state.activeSubmenu === subData.title
                                            ? "mm-active"
                                            : ""
                                        }`}
                                      >
                                        {subData.content &&
                                        subData.content.length > 0 ? (
                                          <>
                                            <Link
                                              to={subData.to}
                                              className={
                                                subData ? "has-arrow" : ""
                                              }
                                              onClick={() => {
                                                handleSubmenuActive(
                                                  subData.title
                                                );
                                              }}
                                            >
                                              {subData.iconStyle}
                                              <span className="nav-text">
                                                {subData.title}
                                              </span>
                                            </Link>
                                            <Collapse
                                              in={
                                                state.activeSubmenu ===
                                                subData.title
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <ul
                                                className={`${
                                                  menuClass === "mm-collapse"
                                                    ? "mm-show "
                                                    : ""
                                                }`}
                                              >
                                                {subData.content &&
                                                  subData.content.map(
                                                    (
                                                      subSubData: any,
                                                      subSubIndex: any
                                                    ) => {
                                                      return (
                                                        <>
                                                          <li key={subSubIndex}>
                                                            <Link
                                                              className={`${
                                                                path ===
                                                                subSubData.to
                                                                  ? "mm-active"
                                                                  : ""
                                                              }`}
                                                              to={subSubData.to}
                                                            >
                                                              {subSubData.title}
                                                            </Link>
                                                          </li>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </ul>
                                            </Collapse>
                                          </>
                                        ) : (
                                          <Link to={subData.to}>
                                            {subData.iconStyle}
                                            {subData.title}
                                          </Link>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </Collapse>
                        </>
                      ) : (
                        <Link to={data.to}>
                          {data.iconStyle}
                          <span className="nav-text">{data.title}</span>
                        </Link>
                      )}
                    </li>
                  );
                }
              })}
        </ul>

        {/* <div className="copyright">
            <p>
              <strong>Acara Ticketing Dashboard</strong> © {d.getFullYear()} All Rights
              Reserved
            </p>
            <p>
              Made with{" "}
              <span className={`heart ${addHeart ? "heart-blast" : ""}`}
                onClick={()=>setAddHeart(!addHeart)}
              ></span>{" "} by DexignZone
            </p>
          </div> */}
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
