import {createSlice} from '@reduxjs/toolkit';
import { IExtracharges } from '../../Common/Interfaces';


enum STATUSES {
    IDLE = "idle",
    ERROR = "error",
    LOADING = "loading",
  }
  const initialState: any = { data: [], status: STATUSES.IDLE };
const ExtrachargesSlice = createSlice({
    name:'extracharges',
    initialState: initialState,
    reducers:{
         addExtracharges(state, action){
             state.push(action.payload)
         },
         removeExtracharges(state, action){
            return state.filter((item:any)=>item.id  != action.payload)
        },
        setExtrachargesSlice(state, action) {
          state.data = action.payload.data;
        },
        setAllExtrachargesSlice(state, action) {
          state.allData = action.payload.data;
        }
    }
});


export const {addExtracharges, removeExtracharges,setExtrachargesSlice,setAllExtrachargesSlice} = ExtrachargesSlice.actions;
export default ExtrachargesSlice.reducer;