import { createSlice } from "@reduxjs/toolkit";
import { IManufacturer } from "../../Common/Interfaces";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const Manufacturer = createSlice({
  name: "manufacturer",
  initialState: initialState,
  reducers: {
    addManufacturer(state, action) {
      state.push(action.payload);
    },
    removeManufacturer(state, action) {
      return state.filter((item: any) => item.id != action.payload);
    },
    setManufacturersSlice(state, action) {
      state.data = action.payload.data;
    },
    setAllManufacturersSlice(state, action) {
      state.allData = action.payload.data;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { addManufacturer, removeManufacturer, setAllManufacturersSlice,setManufacturersSlice } =
  Manufacturer.actions;
export default Manufacturer.reducer;
