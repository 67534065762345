import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const SalesReturnSlice = createSlice({
  name: "salesreturn",
  initialState,
  reducers: {
    addSalesReturn(state, action) {
      state.data = action.payload.data;
    },
    removeSalesReturn(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setSaleReturnPrint(state, action) {
      state.salereturn = action.payload.data;
    },
  },
});

export const { addSalesReturn, removeSalesReturn, setSaleReturnPrint } =
  SalesReturnSlice.actions;
export default SalesReturnSlice.reducer;
