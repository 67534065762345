import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/soullogowhite.png";
import logoText from "../../images/soullogotxtwhite.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useAppDispatch } from "../../store/store";
import { ILogin } from "../../Common/Interfaces/Login/SuperRegister";
import { superLoginThunk } from "../../Thunk/LoginThunk/SuperLoginThunk";
import { loginAction } from "../../store/actions/AuthActions";

function Login() {
  let initialValues: ILogin = {
    uname: "",
    password: "",
  };

  const validationSchema = Yup.object({
    uname: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function handleLogin(arg0: ILogin, navigate: any) {
    dispatch(superLoginThunk(arg0, navigate));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, onSubmit) => {
        handleLogin(values, navigate);
        onSubmit.resetForm();
      }}
    >
      {(formik) => (
        <Form>
          <div className="login-wrapper">
            <div
              className="login-aside-left"
              style={{ backgroundColor: "lightgray" }}
            >
              <Link to="/" className="login-logo">
                <img src={logo} alt="" className="SoulsoftLogo" />
                <img src={logoText} className="SoulsoftLogoTxt" alt="" />
              </Link>
              <div className="login-description">
                <h2 className="text-white mb-4">Soulsoft Infotech Pvt. Ltd.</h2>
                <p className="fs-12">
                  SoulSoft Infotech aspire to be the global sourcing choice of
                  the world market and revolutionizes the way service processes
                  function.
                </p>
                <ul className="social-icons mt-4">
                  <li>
                    <Link
                      to={
                        "https://www.facebook.com/profile.php?id=61553252073568"
                      }
                    >
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={"https://www.instagram.com/soulsoft.infotech/"}>
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"https://www.linkedin.com/company/soulsoftinfotech/"}
                    >
                      <i className="fa fa-linkedin"></i>
                    </Link>
                  </li>
                </ul>
                <div className="mt-5">
                  <Link to={"#"} className="text-white mr-4">
                    Privacy Policy
                  </Link>
                  <Link to={"#"} className="text-white mr-4">
                    Contact
                  </Link>
                  <Link to={"#"} className="text-white">
                    © 2024 Soulsoft
                  </Link>
                </div>
              </div>
            </div>
            <div className="login-aside-right">
              <div className="row m-0 justify-content-center h-100 align-items-center">
                <div className="col-xl-6 col-xxl-8">
                  <div className="authincation-content">
                    <div className="row no-gutters">
                      <div className="col-xl-12">
                        <div className="auth-form">
                          <div className=" mb-3">
                            <h2 className="TextSoulsoft">
                              Welcome to Soulsoft ERP
                            </h2>
                          </div>
                          <h4 className=" mb-4 ">
                            Sign in by entering information below
                          </h4>
                          <div className="form-group">
                            <label className="mb-2 ">
                              <strong>Email</strong>
                            </label>
                            <Field
                              type="email"
                              className="form-control"
                              name="uname"
                            />
                          </div>
                          <div className="text-danger fs-12">
                            <ErrorMessage name="uname" />
                          </div>
                          <div className="form-group">
                            <label className="mb-2 ">
                              <strong>Password</strong>
                            </label>
                            <Field
                              type="password"
                              className="form-control"
                              name="password"
                            />
                          </div>
                          <p className="text-danger fs-12">
                            <ErrorMessage name="password" />
                          </p>
                          <div className="form-row d-flex justify-content-between mt-4 mb-2">
                            <div className="form-group">
                              <div className="custom-control custom-checkbox ml-1 ">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="basic_checkbox_1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="basic_checkbox_1"
                                >
                                  Remember my preference
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              disabled={!formik.isValid || !formik.dirty}
                              className={`btn btn-primary btn-block ${
                                !formik.isValid || !formik.dirty
                                  ? "CursorNA"
                                  : ""
                              }`}
                            >
                              Sign In
                            </button>
                          </div>
                          <div className="new-account mt-3">
                            <p className="">
                              Don't have an account?{" "}
                              <Link
                                className="TextSoulsoft"
                                to="/page-register"
                              >
                                Sign up
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Login;
