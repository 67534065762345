import { createSlice } from "@reduxjs/toolkit";
import { ICategory } from "../../Common/Interfaces";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = { data: [], status: STATUSES.IDLE };

const CategorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {
    addCategory(state, action) {
      state.push(action.payload);
    },
    removeCategory(state, action) {
      return state.filter((item: any) => item.id != action.payload);
    },
    setCategoriesSlice(state, action) {
      state.data = action.payload.data;
    },
    setAllCategoriesSlice(state, action) {
      state.allData = action.payload.data;
    },
  },
});

export const { addCategory, removeCategory,setAllCategoriesSlice, setCategoriesSlice } =
  CategorySlice.actions;
export default CategorySlice.reducer;
