import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const SuperLoginSlice=createSlice({
    name:'superlogin',
    initialState,
    reducers:{
        setSuperLoginSlice(state, action){
            state.data = action.payload.data
        }
        
    }

})

export const{setSuperLoginSlice}=SuperLoginSlice.actions;
export default SuperLoginSlice.reducer

