import { ISuperRegister } from "../../Common/Interfaces/Login/SuperRegister";
import { notifyTopRight ,notifyError} from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import { superRegisterService } from "../../services/Logins/SuperRegisterService";
import { LOGIN_MESSAGES } from "../../Common/Enums/SharedEnums";



export const superRegisterThunk = (payload: ISuperRegister,navigate:any) => {
    return async function (dispatch: any, getstate: any) {
        try {
            const result = await superRegisterService(payload);
            if (result.status === 200) {
                notifyTopRight(LOGIN_MESSAGES.CREATE_ACC) 
                navigate("/login")
            }else{
                notifyError(LOGIN_MESSAGES.UNAME_ERROR)
            }
        }
        catch (ex) {
            notifyError(LOGIN_MESSAGES.UNAME_ERROR)
            console.error('Error save City', ex)
        }
    }
}