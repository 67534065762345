import { AxiosRequestConfig } from "axios";
import { ISuperRegister } from "../../Common/Interfaces/Login/SuperRegister";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl } from "../../Common/Config/Config";


  export const superRegisterService = (payload: ISuperRegister) => {
    let config: AxiosRequestConfig= {
      headers: { "content-type": "multipart/form-data" }
    };
    return axiosServiceInstance.post(`${baseUrl}/api/super/register`, payload, config)
  }
  
 
  