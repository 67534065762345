import { createSlice } from "@reduxjs/toolkit";
import { IBill } from "../../../Common/Interfaces/ITransaction";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const QuotationsSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    addQuotations(state, action) {
      state.data = action.payload.data;
    },
    removeQuotations(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setQuotationPrint(state, action) {
      state.quotation = action.payload.data;
    },
    setQuotations(state, action) {
      state.quotations = action.payload.data;
    },
  },
});

export const {
  addQuotations,
  removeQuotations,
  setQuotationPrint,
  setQuotations,
} = QuotationsSlice.actions;
export default QuotationsSlice.reducer;
