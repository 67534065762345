import axios from 'axios';
import { store } from '../store/store';

const axiosServiceInstance = axios.create();

axiosServiceInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    config.params['auth'] = token;
    return config;
});

export default axiosServiceInstance;
