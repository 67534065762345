import {createSlice} from '@reduxjs/toolkit';
import { IItem } from '../../Common/Interfaces';

enum STATUSES {
    IDLE='idle',
    ERROR='error',
    LOADING='loading'
}
const initialState:any=
    {
        data:[],
        status: STATUSES.IDLE
    }
;

const ItemSlice = createSlice({
    name:'item',
    initialState: initialState,
    reducers:{
         addItem(state, action){
             state.push(action.payload)
         },
         removeItem(state, action){
            return state.filter((item:any)=>item.id  !== action.payload)
        },
        setItemsSlice(state,action){
            state.data = action.payload.data
        },
        setAllItemsSlice(state,action){
            state.allData = action.payload.data
        }
    }
});


export const {addItem, removeItem, setAllItemsSlice, setItemsSlice} = ItemSlice.actions;
export default ItemSlice.reducer;