import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = { data: [], status: STATUSES.IDLE };

const ActivitySlice = createSlice({
  name: "activity",
  initialState: initialState,
  reducers: {
    
    setActivitySlice(state, action) {
      state.data = action.payload.data;
    }
  },
});

export const { setActivitySlice}=  ActivitySlice.actions;
export default ActivitySlice.reducer;
