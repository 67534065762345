import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = { data: [], status: STATUSES.IDLE };

const ProfileTabSlice = createSlice({
  name: "profileTab",
  initialState: initialState,
  reducers: {
    
    setProfileTabSlice(state, action) {
      state.data = action.payload.data;
    },
    setSingleProfileTabSlice(state, action) {
      state.singleData = action.payload.data;
    },
  },
});

export const { setProfileTabSlice, setSingleProfileTabSlice}=  ProfileTabSlice.actions;
export default ProfileTabSlice.reducer;
