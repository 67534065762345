import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = { data: [], status: STATUSES.IDLE };

const ProfileActivitySlice = createSlice({
  name: "profileActivity",
  initialState: initialState,
  reducers: {
    
    setProfileActivitySlice(state, action) {
      state.data = action.payload.data;
    },
    setSingleProfileActivitySlice(state, action) {
      state.singleData = action.payload.data;
    },
  },
});

export const { setProfileActivitySlice, setSingleProfileActivitySlice}=  ProfileActivitySlice.actions;
export default ProfileActivitySlice.reducer;
