import {createSlice} from '@reduxjs/toolkit';

enum STATUSES {
    IDLE='idle',
    ERROR='error',
    LOADING='loading'
}
const initialState:any=
    {
        data:[],
        status: STATUSES.IDLE
    }
;

const PartySlice = createSlice({
    name:'party',
    initialState: initialState,
    reducers:{
         addParty(state, action){
            return [...state, action.payload];
         },
         removeParty(state, action){
            return state.filter((item:any)=>item.id  != action.payload)
        },
        setPartySlice(state,action){
            state.data  = action.payload.data
        },
        setAllPartySlice(state,action){
            state.allData  = action.payload.data
        }
    }
});


export const {addParty, removeParty, setPartySlice,setAllPartySlice} = PartySlice.actions;
export default PartySlice.reducer;