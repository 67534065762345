import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const IconSlice=createSlice({
    name:'icon',
    initialState,
    reducers:{
        setIconSlice(state, action){
            state.data = action.payload.data
        }
        
    }

})
export const{setIconSlice}=IconSlice.actions;
export default IconSlice.reducer

