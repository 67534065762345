import { IItem, IParty } from "../../../../Common/Interfaces";
import {
  IPurchase,
  IPurchaseItems,
} from "../../../../Common/Interfaces/ITransaction";
import { getTodayDate } from "../../../../Common/Utils/CommonFunctions";

export const defaultParty: IParty = {
  Id: "",
  PartyNo: "",
  PartyName: "",
  PartyType: "supplier",
  Address: "",
  State: "Maharashtra",
  Email: "",
  Mobile: "",
  PAN: "",
  ADHAR: "",
  Desc: "",
  LedgerId: "",
  OpeningBalance: 0.0,
  CreditLimit: 0.0,
  CreditPeriod: 0.0,
  GSTIN: "",
  Status: 1,
  CreatedBy: 1,
  UpdatedBy: 1,
  FixedRate: "",
  Route: "",
};

export const defaultItem: IItem = {
  Id: "",
  UnitId: "",
  UnitName: "",
  ItemName: "",
  Desc: "",
  Barcode: "",
  OpeningQty: 0,
  CategoryId: "",
  CategoryName: "",
  ItemCode: "",
  TaxRate: 0,
  HSNCode: "",
  ManufacturerId: "",
  ManufacturerName: "",
  Packing: "",
  Shelf: "",
  MaxLevel: "",
  RecorderLevel: 0,
  LastPurchaseRate: 0,
  MRP: 0,
  Discount: 0,
  CashRate: 0,
  CreditRate: 0,
  WholesaleRate: 0,
  Rate4: 0,
  Rate5: 0,
  Status: true,
  CreatedBy: 0,
  UpdatedBy: "",
};

export const defaultPage = { Page: 1, PerPage: 10 };

export let purchaseItems: IPurchaseItems = {
  Id: "",
  ItemName: "",
  ItemId: "",
  ItemDescription: "",
  UnitId: "",
  Qty: 0,
  FreeQty: 0,
  Rate: 0,
  ItemTotal: 0,
  DiscPer: 0,
  DiscAmt: 0,
  TaxableAmt: 0,
  TaxPer1: 0,
  TaxAmt1: 0,
  TaxPer2: 0,
  TaxAmt2: 0,
  TaxPer3: 0,
  TaxAmt3: 0,
  TaxPer4: 0,
  TaxAmt4: 0,
  TaxPer5: 0,
  TaxAmt5: 0,
  NetAmt: 0,
  BatchNo: "",
  MfgDate: "",
  ExpDate: "",
  SalesRate: 0,
  ActualRate: 0,
  PurchaseRate: 0,
  TotTax: 0,
};

export let initialFormikPurchaseValues: IPurchase = {
  Id: "", // Placeholder for the Id, as its not provided in the JSON
  PartyName: "",
  Prefix: "",
  PrefixPurchaseNo: "",
  Address: "",
  GSTIN: "",
  PurchaseNo: "",
  PurchaseDate: getTodayDate(),
  SupplierId: "",
  PaymentType: "CASH",
  BankLedgerId: "",
  PurchaseType: "NON GST",
  SupplierBillNo: "",
  SubPaymentType: "",
  AccountName: "",
  TotQty: 0,
  FreeQty: 0,
  TotItemAmt: 0,
  TotDiscPer: 0,
  TotDiscAmt: 0,
  TotTaxable: 0,
  TotTax: 0,
  NetTotal: 0,
  RoundOff: 0,
  BillAmt: 0,
  ExtraPurchaseCharges: 0,
  ExtraPurchaseDiscount: 0,
  NetBillAmt: 0,
  PaymentAmt: 0,
  BalanceBillAmt: 0,
  ByHand: "",
  VehicleDetails: "",
  Narration: "",
  IsReturn:false,
  ReturnPurchaseDate:"",
  IsPurchaseReturn: "",
  IsPurchaseFromPO: "",
  POId: "",
  FirmId: "",
  CreatedBy: "",
  CreatedOn: "",
  UpdatedBy: "",
  UpdatedOn: "",
  ExtraPurchaseDiscountInPer: 0,
  PurchaseItems: [purchaseItems],
};

export let defaultPurchaseItems: IPurchaseItems = {
  Id: "",
  ItemName: "",
  ItemId: "",
  ItemDescription: "",
  UnitId: "",
  Qty: 0,
  FreeQty: 0,
  Rate: 0,
  ItemTotal: 0,
  DiscPer: 0,
  DiscAmt: 0,
  TaxableAmt: 0,
  TaxPer1: 0,
  TaxAmt1: 0,
  TaxPer2: 0,
  TaxAmt2: 0,
  TaxPer3: 0,
  TaxAmt3: 0,
  TaxPer4: 0,
  TaxAmt4: 0,
  TaxPer5: 0,
  TaxAmt5: 0,
  NetAmt: 0,
  BatchNo: "",
  MfgDate: "",
  ExpDate: "",
  SalesRate: 0,
  ActualRate: 0,
  PurchaseRate: 0,
  TotTax: 0,
};
