import {createSlice} from '@reduxjs/toolkit'
enum STATUSES {
    IDLE='idle',
    ERROR='error',
    LOADING='loading'
}
const initialState:any=
    {
        data:[],
        status: STATUSES.IDLE
    }
;

const UnitSlice = createSlice({
    name:'unit',
    initialState,
    reducers:{
        add(state, action){
            state.data.push(action.payload)
        },
        remove(state, action){
           return state.filter((item:any)=>item.id != action.payload);
        },
        setUnitSlice(state, action){
            state.data=[]
            state.data = action.payload.data
        },
        setAllUnitSlice(state, action){
            state.data=[]
            state.allData = action.payload.data
        },
        setStatus(state, action){
            state.status = action.payload
        }
        
    }
});

export const {add, remove, setUnitSlice,setAllUnitSlice, setStatus} = UnitSlice.actions;
export default UnitSlice.reducer;
