import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const LoginConfirmedSlice=createSlice({
    name:'loginconfirmed',
    initialState,
    reducers:{
        setLoginConfirmedSlice(state, action){
            state.data = action.payload;
            state.errorMessage = "";
            state.successMessage = "Login Successfully Completed";
            state.showLoading = false;
        }
        
    }

})

export const{setLoginConfirmedSlice}=LoginConfirmedSlice.actions;
export default LoginConfirmedSlice.reducer

