import { createSlice } from "@reduxjs/toolkit";
import { ITandC } from "../../Common/Interfaces";

enum STATUSES {
  IDLE='idle',
  ERROR='error',
  LOADING='loading'
}
const initialState:any=
  {
      data:[],
      status: STATUSES.IDLE
  }

const TermsAndConditionsSlice = createSlice({
  name: "terms",
  initialState: initialState,
  reducers: {
    addTerms(state, action) {
      state.push(action.payload);
    },
    removeTerms(state, action) {
      return state.filter((item: any) => item.id != action.payload);
    },
      setTermsSlice(state, action){
        state.data = action.payload.data
    },
    setAllTermsSlice(state, action){
      state.allData = action.payload.data
  }
    },
  },
);

export const { addTerms, removeTerms,setTermsSlice,setAllTermsSlice } = TermsAndConditionsSlice.actions;
export default TermsAndConditionsSlice.reducer;
