import { ILogin } from "../../Common/Interfaces/Login/SuperRegister";
import { notifyError } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import { LOGIN_MESSAGES } from "../../Common/Enums/SharedEnums";
import { getSuperLoginDataService, superLoginService } from "../../services/Logins/SuperLoginService";
import { setSuperLoginSlice } from "../../store/Slices/Login/SuperLoginSlice";
import { runLogoutTimer, saveTokenInLocalStorage } from "../../services/AuthLoginService";
import { setCommonLoginDataSlice } from "../../store/Slices/Login/CommonLoginDataSlice";

export const superLoginThunk = (payload: ILogin,navigate:any) => {
    return async function (dispatch: any, getstate: any) {
        try {
            const result = await superLoginService(payload);
            if (result.data.status === 200) {
                dispatch(setCommonLoginDataSlice(result))
                localStorage.setItem('userType', JSON.stringify(result.data.type));
                saveTokenInLocalStorage(result.data.tokenDetails,dispatch)
                runLogoutTimer(dispatch,result.data.tokenDetails.expiresIn * 1000,navigate);
                navigate("/dashboard")
            }else{
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
            }
        }
        catch (ex:any) {
            if (ex.response && ex.response.status === 401) {
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
              } else {
                console.error('Error', ex)
              }
        }
    }
}

export const getSuperLoginDataThunk = (id: string) => {
    return async function (dispatch: any, getstate: any) {
        try {
            const result = await getSuperLoginDataService(id);
            if (result.data.status === 200) {
                dispatch(setCommonLoginDataSlice(result))
            }else{
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
            }
        }
        catch (ex:any) {
            if (ex.response && ex.response.status === 401) {
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
              } else {
                console.error('Error', ex)
              }
        }
    }
}