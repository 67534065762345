import { createSlice } from "@reduxjs/toolkit";
import { IFirm } from "../../Common/Interfaces";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const FirmSlice = createSlice({
  name: "firm",
  initialState: initialState,
  reducers: {
    addFirm(state, action) {
      state.push(action.payload);
    },
    removeFirm(state, action) {
      return state.filter((item: any) => item.id != action.payload);
    },
    setFirmSlice(state, action) {
      state.data = action.payload.data;
    },
    setAllFirmSlice(state, action) {
      state.allData = action.payload.data;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { addFirm, removeFirm, setFirmSlice,setAllFirmSlice, setStatus } =
  FirmSlice.actions;
export default FirmSlice.reducer;
