import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const CitySlice=createSlice({
    name:'city',
    initialState,
    reducers:{
        setCitySlice(state, action){
            state.data = action.payload.data
        }
        
    }

})

export const{setCitySlice}=CitySlice.actions;
export default CitySlice.reducer

