import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const TabSlice=createSlice({
    name:'tab',
    initialState,
    reducers:{
        add(state,action){
            state.data.push(action.payload)
        },
        remove(state,action){
            state.data.push((tab:any)=>tab.id!=action.payload)
        },
        setTabSlice(state, action){
            state.data=[]
            state.data = action.payload.data
        },
        setStatus(state, action){
            state.status = action.payload
        }
        
    }

})
export const{add,remove,setTabSlice,setStatus}=TabSlice.actions;
export default TabSlice.reducer

