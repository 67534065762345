import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const RoleSlice=createSlice({
    name:'role',
    initialState,
    reducers:{
      
        setRoleSlice(state, action){
            state.data=[]
            state.data = action.payload.data
        }
        
        
    }

})
export const{setRoleSlice}=RoleSlice.actions;
export default RoleSlice.reducer

