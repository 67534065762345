import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
    IDLE='idle',
    ERROR='error',
    LOADING='loading'
}
const initialState:any=
    {
        data:[],
        status: STATUSES.IDLE
    }
;

const LedgerMasterSlice = createSlice({
    name:'ledgermaster',
    initialState: initialState,
    reducers:{
         addLedger(state, action){
             state.data=action.payload
         },
         removeLedger(state, action){
            return state.filter((item:any)=>item.id  !== action.payload)
        },
        
    }
});


export const {addLedger, removeLedger} = LedgerMasterSlice.actions;
export default LedgerMasterSlice.reducer;