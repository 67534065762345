import { ILogin } from "../../Common/Interfaces/Login/SuperRegister";
import { notifyError, notifyTopRight } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import { LOGIN_MESSAGES } from "../../Common/Enums/SharedEnums";
import { empLoginService, getEmpLoginDataService } from "../../services/Logins/EmpLoginService";
import { setEmpLoginSlice } from "../../store/Slices/Login/EmpLoginSlice";
import { setLoginConfirmedSlice } from "../../store/Slices/Login/LoginConfirmedSlice";
import { runLogoutTimer, saveTokenInLocalStorage } from "../../services/AuthLoginService";
import { setCommonLoginDataSlice } from "../../store/Slices/Login/CommonLoginDataSlice";

export const empLoginThunk = (payload: ILogin,navigate:any) => {
    return async function (dispatch: any, getstate: any) {
        try {
            const result = await empLoginService(payload);
            if (result.data.status === 200) {
                dispatch(setCommonLoginDataSlice(result));
                localStorage.setItem('userType', JSON.stringify(result.data.type));
                saveTokenInLocalStorage(result.data.tokenDetails,dispatch)
                runLogoutTimer(dispatch,result.data.tokenDetails.expiresIn * 1000,navigate);
                navigate("/dashboard")
            }else{
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
            }
        }
        catch (ex:any) {
            if (ex.response && ex.response.status === 401) {
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
              } else {
                console.error('Error', ex)
              }
        }
    }
}

export const getEmpLoginDataThunk = (id: string) => {
    return async function (dispatch: any, getstate: any) {
        try {
            const result = await getEmpLoginDataService(id);
            if (result.data.status === 200) {
                dispatch(setCommonLoginDataSlice(result))
            }else{
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
            }
        }
        catch (ex:any) {
            if (ex.response && ex.response.status === 401) {
                notifyError(LOGIN_MESSAGES.LOGIN_ERROR)
              } else {
                console.error('Error', ex)
              }
        }
    }
}