import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
    IDLE = "idle",
    ERROR = "error",
    LOADING = "loading",
  }
  const initialState: any = {
    data: [],
    status: STATUSES.IDLE,
  };
  const PurchaseOrderSlice = createSlice({
    name: "purchaseorder",
    initialState,
    reducers: {
      addPurchaseOrder(state, action) {
        state.data = action.payload.data;
      },
      removePurchaseOrder(state, action) {
        return state.filter((item: any) => item.id !== action.payload);
      },
      setPurchaseOrderPrint(state, action) {
        state.purchaseorder = action.payload.data;
      },
      setPurchaseOrder(state, action) {
        state.purchaseorders = action.payload.data;
      },
    },
  });
  
  export const {
    addPurchaseOrder,removePurchaseOrder,setPurchaseOrderPrint,setPurchaseOrder
  } = PurchaseOrderSlice.actions;
  export default PurchaseOrderSlice.reducer;
  