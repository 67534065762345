import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const PurchaseReturnSlice = createSlice({
  name: "purchasereturn",
  initialState,
  reducers: {
    addPurchaseReturn(state, action) {
      state.data= action.payload.data;;
    },
    removePurchaseReturn(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setPurchaseReturnPrint(state, action) {
      state.purchasereturn = action.payload.data;
    },
  },
});

export const {
  addPurchaseReturn,
  removePurchaseReturn,
  setPurchaseReturnPrint,
} = PurchaseReturnSlice.actions;
export default PurchaseReturnSlice.reducer;
