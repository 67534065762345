import { IItem, IParty } from "../../../../Common/Interfaces";
import { IBill, IBillItems } from "../../../../Common/Interfaces/ITransaction";
import { addTrailingZeros } from "../../../../Common/Utils/Calculate";
import { getTodayDate } from "../../../../Common/Utils/CommonFunctions";

export const defaultParty: IParty = {
  Id: "",
  PartyNo: "",
  PartyName: "",
  PartyType: "customer",
  Address: "",
  State: "Maharashtra",
  Email: "",
  Mobile: "",
  PAN: "",
  ADHAR: "",
  Desc: "",
  LedgerId: "",
  OpeningBalance: 0.0,
  CreditLimit: 0.0,
  CreditPeriod: 0.0,
  GSTIN: "",
  Status: 1,
  CreatedBy: 1,
  UpdatedBy: 1,
  FixedRate: "",
  Route: "",
};

export const defaultItem: IItem = {
  Id: "",
  UnitId: "",
  UnitName: "",
  ItemName: "",
  Desc: "",
  Barcode: "",
  OpeningQty: 0,
  CategoryId: "",
  CategoryName: "",
  ItemCode: "",
  TaxRate: 0,
  HSNCode: "",
  ManufacturerId: "",
  ManufacturerName: "",
  Packing: "",
  Shelf: "",
  MaxLevel: "",
  RecorderLevel: 0,
  LastPurchaseRate: 0,
  MRP: 0,
  Discount: 0,
  CashRate: 0,
  CreditRate: 0,
  WholesaleRate: 0,
  Rate4: 0,
  Rate5: 0,
  Status: true,
  CreatedBy: 0,
  UpdatedBy: "",
};

export const defaultPage = { Page: 1, PerPage: 10 };

export let billItems: IBillItems = {
  ItemId: "",
  ItemName: "",
  ItemDescription: "",
  UnitId: "",
  Qty: 0,
  Rate: 0,
  ActualRate: 0,
  ItemTotal: 0,
  DiscPer: 0,
  DiscAmt: 0,
  TaxableAmt: 0,
  TaxPer1: 0,
  TaxAmt1: 0,
  TaxPer2: 0,
  TaxAmt2: 0,
  TaxPer3: 0,
  TaxAmt3: 0,
  TaxPer4: 0,
  TaxAmt4: 0,
  TaxPer5: 0,
  TaxAmt5: 0,
  TotTax: 0,
  NetAmt: 0,
  BatchNo: 0,
  MfgDate: "",
  ExpDate: "",
  PurchaseRate: 0,
  SalesRate: 0,
};

export let defaultFormikBillValues: IBill = {
  Id: "",
  Prefix: "",
  BillNo: "",
  PrefixBillNo: "",
  BillDate: getTodayDate(),
  CustId: "",
  PartyName: "",
  RateTypeId: "",
  BillType: "",
  PaymentType: "CASH",
  BankLedgerId: "",
  SubPaymentType: "",
  AccountName: "",
  Address: "",
  GSTIN: "",
  TotQty: 0,
  TotItemAmt: 0,
  TotDiscPer: 0,
  TotDiscAmt: 0,
  TotTaxable: 0,
  TotTax: 0,
  NetTotal: 0,
  ExtraBillCharges: 0,
  ExtraBillDiscount: 0,
  ExtraBillDiscountInPer: 0,
  BillAmt: 0,
  RoundOff: 0,
  NetBillAmt: 0,
  ReceiptAmt: 0,
  BalanceBillAmt: 0,
  Narration: "",
  IsReturn: false,
  ReturnBillDate: "",
  CreatedBy: "",
  CreatedOn: "",
  ByHand: "",
  VehicleDetails: "",
  BillItems: [billItems],
};

export let defaultBillItem: IBillItems = {
  ItemId: "",
  ItemName: "",
  ItemDescription: "",
  UnitId: "",
  Qty: 0,
  Rate: 0,
  ActualRate: 0,
  ItemTotal: 0,
  DiscPer: 0,
  DiscAmt: 0,
  TaxableAmt: 0,
  TaxPer1: 0,
  TaxAmt1: 0,
  TaxPer2: 0,
  TaxAmt2: 0,
  TaxPer3: 0,
  TaxAmt3: 0,
  TaxPer4: 0,
  TaxAmt4: 0,
  TaxPer5: 0,
  TaxAmt5: 0,
  TotTax: 0,
  NetAmt: 0,
  BatchNo: 0,
  MfgDate: "",
  ExpDate: "",
  PurchaseRate: 0,
  SalesRate: 0,
};
