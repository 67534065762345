import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
// import { checkAutoLogin } from "./services/AuthService";
import { checkAutoLogin } from "./services/AuthLoginService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Dashboard from "./jsx/components/Dashboard/Dashboard";
import Login from "./jsx/Logins/Login";
// import Login from "./jsx/pages/Login";
import "./App.css";
import { ToastContainer } from "react-toastify";
import Register from "./jsx/Logins/Register";
import EmployeeLogin from "./jsx/Logins/EmployeeLogin";
import Error404Login from "./jsx/pages/Error404Login";

// const SignUp = lazy(() => import('./jsx/pages/Registration'));
// const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
// const Login = lazy(() => {
//     return new Promise(resolve => {
// 		setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
// 	});
// });

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    checkAutoLogin(dispatch, navigate,pathname);
  }, [navigate]);

  let routeblog = (
    <Routes>
      {/* <Route path='/login' element={<Dashboard />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path='/page-register' element={<Register />} />
      <Route path="/emplogin" element={<EmployeeLogin />} />
      <Route path="*" element={<Error404Login />} />
      {/* <Route path='/page-forgot-password' element={<ForgotPassword />} /> */}
    </Routes>
  );
  if (props.isAuthenticated) {
    return (
      <>
		    <ToastContainer />
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <ToastContainer />
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
