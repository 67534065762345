import { createSlice } from "@reduxjs/toolkit";
enum STATUSES{
    IDLE='idle',
    ERROR='error',
    LOADING='loading'

}

const initialState:any=
{
    data:[],
    status:STATUSES.IDLE
}

const CountrySlice=createSlice({
    name:'country',
    initialState,
    reducers:{
        setCountrySlice(state, action){
            state.data = action.payload.data
        }
        
    }

})

export const{setCountrySlice}=CountrySlice.actions;
export default CountrySlice.reducer

