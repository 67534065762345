import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = { data: [], status: STATUSES.IDLE };

const ProfileRoleSlice = createSlice({
  name: "profileRole",
  initialState: initialState,
  reducers: {
    
    setProfileRoleSlice(state, action) {
      state.data = action.payload.data;
    },
    setSingleProfileRoleSlice(state, action) {
      state.singleData = action.payload.data;
    },
  },
});

export const { setProfileRoleSlice, setSingleProfileRoleSlice}=  ProfileRoleSlice.actions;
export default ProfileRoleSlice.reducer;
